import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useI18next } from 'gatsby-plugin-react-i18next';

const AppContext = createContext();

// eslint-disable-next-line react/function-component-definition
const AppContextProvider = ({ children, initialData: passedInitialData }) => {
  const { language } = useI18next();
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const [initialData, setInitialData] = useState(passedInitialData);
  // const [drawerMenuOpen, onDrawerMenuToggle] = useState(false);
  const themes = ['default', 'secondary'];
  const [themeToUse, setThemeToUse] = useState(Cookies.get('theme-to-use') || themes[0]);
  const [dynamicTheme, setDynamicTheme] = useState({
    direction: dir,
    palette: {
      mode: Cookies.get('palette-mode') || 'light',
    },
  });
  const [baseBackdrop, setBaseBackdrop] = useState({ open: false, child: null });
  // const [backBreadcrumbs, setBackBreadcrumbs] = useState({ to: '/', breadcrumbs: [], Actions: null }); // to: for Back-To, breadcrumbs: for paths of breadcrumbs, Actions: for React.element

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const sharedState = {
    // drawerMenuOpen,
    // onDrawerMenuToggle,
    themes,
    themeToUse,
    setThemeToUse,
    dynamicTheme,
    setDynamicTheme,
    initialData,
    setInitialData,
    baseBackdrop,
    setBaseBackdrop,
    // backBreadcrumbs,
    // setBackBreadcrumbs,
  };

  return (
    <AppContext.Provider value={sharedState}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initialData: PropTypes.shape({
    marketPlace: PropTypes.arrayOf(PropTypes.object),
    installedPlugins: PropTypes.arrayOf(PropTypes.object),
  }),
};

AppContextProvider.defaultProps = {
  initialData: {
    marketPlace: [],
    installedPlugins: [],
  },
};

const useAppContext = () => (useContext(AppContext));
const appContextHOC = (SessionComponent) => {
  // eslint-disable-next-line no-param-reassign
  SessionComponent.contextType = AppContext;

  return SessionComponent;
};

export {
  AppContext,
  AppContextProvider,
  useAppContext,
  appContextHOC,
};
