import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import zhTWLocale from 'date-fns/locale/zh-TW';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { useAppContext } from '../services/context/AppContext';
import createProfessionalTheme from './theme';

// ajax global setup
const setupAxios = () => {
  axios.defaults.timeout = 6000;

  // const tt = i18n.getFixedT(null, 'app');
  // const et = i18n.getFixedT(null, 'errors');

  // Intercept to Modify the config before request is sent
  // axios.interceptors.request.use((config) => {
  //   return config;
  // });

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.code === 'ECONNABORTED' || error.response?.status === 408) {
        // status, statusText
        console.error(et('timeout'));
        // toast.error(et('timeout'));
        error.response = {
          // status: 'missing',
          // statusText: '超過五秒！',
          data: {
            code: 'timeout',
            // message: '超過xx秒！',
          },
        };
      }

      if (error.response?.status === 401) {
        // Redirect to login page
        // window.location.href = `/auth/credentials-signin?callbackUrl=${encodeURIComponent(window.location.href)}`;
      }

      if (error.response?.status === 500) {
        console.error(tt('system-error')); // 系統錯誤，請稍後再試！
        // toast.error(tt('system-error'));
      }

      return Promise.reject(error);
    },
  );
};

const localeMap = {
  en: enLocale,
  'zh-Hant': zhTWLocale,
};

const ThemeProvider = ({ children }) => {
  const {
    // baseBackdrop,
    // setBaseBackdrop,
    themeToUse,
    dynamicTheme,
  } = useAppContext();
  const generatedTheme = createProfessionalTheme(themeToUse, dynamicTheme); // parameter1: which theme to use: "default" || "secondary", parameter2: theme to over write like {palette: {type: 'dark|light'}}

  const { language, languages, originalPath } = useI18next();
  console.log('language', language, languages, originalPath);

  // const handleBackdropClick = useCallback(() => {
  //   setBaseBackdrop({ open: false, child: null });
  // }, []);

  useEffect(() => {
    setupAxios();
  }, []);

  return (
    <MaterialThemeProvider theme={generatedTheme}>
      <StyledThemeProvider theme={generatedTheme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={DateAdapter} locale={localeMap[language]}>
          <SnackbarProvider maxSnack={6} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            {children}
          </SnackbarProvider>
        </LocalizationProvider>
      </StyledThemeProvider>
    </MaterialThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ThemeProvider;
