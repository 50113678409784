import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// import blue from '@mui/material/colors/blue';
// import red from '@mui/material/colors/red';
// import green from '@mui/material/colors/green';
// import deepOrange from '@material-ui/core/colors/deepOrange';
// import amber from '@material-ui/core/colors/amber';
// import pink from '@material-ui/core/colors/pink';
// import red from '@material-ui/core/colors/red';
import _merge from 'lodash.merge';

export default function createDefaultTheme(dynamicTheme) {
  const { palette: { mode: paletteMode } } = dynamicTheme;
  const defaultTheme = {
    palette: {
      background: {
        paper2: (paletteMode === 'dark') ? '#212121' : '#fff',
      },
    },
    typography: {
      fontFamily: '"PingFang TC", PingFangTC-Regular, "Noto Sans TC", STHeitiTC-light, STHeiti, "Microsoft JhengHei", "微軟正黑體", sans-serif',
    },
  };
  const customTheme = _merge(defaultTheme, dynamicTheme);
  const theme = responsiveFontSizes(createTheme(customTheme));

  if (typeof window !== 'undefined') {
    window.customTheme = customTheme;
    window.theme = theme;
  }

  return theme;
}
