import { combineReducers, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer from './user-reducer';

const reducers = combineReducers({
  user: userReducer,
});

const composeEnhancers = composeWithDevTools({
  // Specify custom devTools options
});
const store = createStore(reducers, /* preloadedState, */ composeEnhancers(
  // applyMiddleware(...middleware),
  // other store enhancers if any
));

export default store;
