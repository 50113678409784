import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import deepOrange from '@mui/material/colors/deepOrange';
import lightGreen from '@mui/material/colors/lightGreen';
// import amber from '@material-ui/core/colors/amber';
// import pink from '@material-ui/core/colors/pink';
// import red from '@material-ui/core/colors/red';
import _merge from 'lodash.merge';

export default function createDefaultTheme(dynamicTheme) {
  const { palette: { type: paletteMode } } = dynamicTheme;
  const defaultTheme = {
    palette: {
      primary: deepOrange,
      secondary: lightGreen,
    },
    typography: {
      fontFamily: '"PingFang TC", PingFangTC-Regular, "Noto Sans TC", STHeitiTC-light, STHeiti, "Microsoft JhengHei", "微軟正黑體", sans-serif',
    },
  };
  const customTheme = _merge(defaultTheme, dynamicTheme);
  const theme = responsiveFontSizes(createTheme(customTheme));

  if (typeof window !== 'undefined') {
    window.customTheme = customTheme;
    window.theme = theme;
  }

  return theme;
}
