import produce from 'immer';

// export const PROJECT_ACTION = {
//   UPDATE_ROLE: Symbol('UPDATE_ROLE'),
//   CLEAN_ALL: Symbol('CLEAN_ALL'),
// };

// Only use addedTaskIds for now. Coz, it's new. Others needed code modifications and may affect other projects.
const initialState = {
  role: 1,
};

const userReducer = (state = initialState, action = {}) => {
  console.log('User Reducer:', initialState, action);
  switch (action.type) {
    case 'UPDATE_ROLE':
      return produce(state, (draft) => {
        draft.role = action.role;
      });

    case 'RESET':
      return produce(state, () => initialState);

    default:
      return state;
  }
};

export default userReducer;
