import createDefaultTheme from './default';
import createSecondaryTheme from './secondary';

export default function createProfessionalTheme(themeName, dynamicTheme) {
  switch (themeName) {
    case 'secondary':
      return createSecondaryTheme(dynamicTheme);
    default:
      return createDefaultTheme(dynamicTheme);
  }
}
